"use client";

import { Options } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import {
  ImageLoaderPropsWithConfig,
  imageConfigDefault,
} from "next/dist/shared/lib/image-config";
import defaultLoader from "next/dist/shared/lib/image-loader";

import { IHeroTeaserHomeFields } from "../../@types/generated/contentful";
import { withHttps } from "../../utils/helperFunctions";
import RichTextRenderer from "../content-section/helper/richTextRenderer";
import CTAButton from "../cta-button";

// Overloading default richtext options to display subtext as white
const richtextOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography variant="heroSubtitle" marginBottom={useTheme().spacing(3)}>
        {children}
      </Typography>
    ),
  },
};

/**
 * Component renders a teaser with up to three background images and a CTA
 * @param {IHeroTeaserHomeFields} props contentful hero teaser home props
 * @returns JSX.Element
 */
const HeroTeaserHome = ({
  headline,
  imageSm,
  imageMd,
  imageXl,
  subtext,
  button,
}: IHeroTeaserHomeFields) => {
  const theme = useTheme();

  let heroTeaserHomeStyle: SxProps<Theme> = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "end",
    justifyContent: "flex-end",
    flexDirection: "colummn",
    position: "relative",
    aspectRatio: {
      xs: "9/16",
      sm: "4/3",
      lg: "16/9",
    },
    "@supports not (aspect-ratio: 9/16)": {
      [theme.breakpoints.down("sm")]: {
        height: "736px",
        "> div": {
          alignSelf: "flex-end",
        },
      },
      [theme.breakpoints.between("sm", "md")]: {
        height: "736px",
        "> div": {
          alignSelf: "flex-end",
        },
      },
    },
    maxHeight: 736,
  };

  const imageLoader = (src: string, width: number) => {
    return defaultLoader({
      src: withHttps(src),
      width,
      config: {
        ...imageConfigDefault,
        domains: ["images.ctfassets.net", "cdn.shopify.com"],
      },
    } as ImageLoaderPropsWithConfig);
  };

  const heroTeaserHomeContentContainerStyle = {
    maxWidth: theme.breakpoints.values.lg,
    boxSizing: "border-box",
    paddingX: theme.spacing(2),
    paddingY: theme.spacing(4),
    marginTop: theme.spacing(8),
    textAlign: "left",
    padding: {
      xs: theme.spacing(4, 2),
      md: theme.spacing(14, 14, 14, 14),
    },
    marginX: "auto",
    zIndex: 1,
  };

  const heroTeaserHomeContentLimitingContainerStyle = {
    maxWidth: theme.breakpoints.values.sm,
    backgroundColor:
      theme.components?.HeroTeaserHomeContentContainer.backgroundColor,
    padding: theme.components?.HeroTeaserHomeContentContainer.padding,
  };

  return (
    <Box sx={heroTeaserHomeStyle} className="hero-teaser-home">
      <picture>
        <source
          srcSet={imageLoader(imageXl.fields.file.url, 1600)}
          media="(min-width: 900px)"
        />
        <source
          srcSet={imageLoader(
            imageMd ? imageMd.fields.file.url : imageXl.fields.file.url,
            1080
          )}
          media="(min-width: 600px)"
        />
        <img
          src={imageLoader(
            imageSm ? imageSm.fields.file.url : imageXl.fields.file.url,
            640
          )}
          alt={imageXl.fields.description}
          style={{
            zIndex: 0,
            objectFit: "cover",
            objectPosition: "top",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
          }}
        />
      </picture>
      <Box
        sx={{
          ...heroTeaserHomeContentContainerStyle,
          width: theme.components!.HeroTeaserHomeContentContainer.width,
        }}
      >
        <Box
          sx={{
            ...heroTeaserHomeContentLimitingContainerStyle,
            maxWidth: theme.components!.HeroTeaserHomeContentContainer.maxWidth,
          }}
        >
          <Typography sx={{}} variant={"heroMain" as any}>
            {headline}
          </Typography>

          {subtext && (
            <RichTextRenderer
              richTextDocument={subtext}
              options={richtextOptions}
            />
          )}

          {button && (
            <Box
              sx={{
                marginTop: theme.spacing(4),
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
              }}
            >
              <CTAButton
                {...button.fields}
                backgroundColor={
                  theme.components!.HeroTeaserHomeButton.backgroundColor
                }
                type="big"
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default HeroTeaserHome;
