"use client";

import CloseIcon from "@mui/icons-material/Close";
import { Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";

import { useEffect, useRef, useState } from "react";

import { ICountdownBannerFields } from "../../@types/generated/contentful";
import useContentSectionThemeContext from "../../hooks/useContentSectionThemeContext";
import useLink from "../../hooks/useLink";
import { ContentSectionThemeContext } from "../../utils/contexts";
import { withHttps } from "../../utils/helperFunctions";

const CountdownBar: React.FC<ICountdownBannerFields> = (countdownBarFields) => {
  const {
    button,
    title,
    countdownEnd,
    description,
    backgroundColor,
    image,
    buttonColor,
  } = countdownBarFields;

  const Ref = useRef(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const themeContextProvider = useContentSectionThemeContext(backgroundColor);
  const fontColor = useContentSectionThemeContext(backgroundColor).textColor;
  const buttonTextColor = useContentSectionThemeContext(buttonColor).textColor;

  const [timeString, setTimeString] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [timeOffset, setTimeOffset] = useState(0);
  const [isClosed, setIsClosed] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isCmpVisible, setIsCmpVisible] = useState(false);
  const [isOvertime, setIsOvertime] = useState(false);

  const endDate = new Date(countdownEnd as string).getTime();

  const fetchServerTime = async () =>
    await fetch("/api/time")
      .then((res) => res.json())
      .then((data) => {
        setCurrentTime(parseInt(data.time));
        if (typeof window != "undefined") {
          setTimeOffset(window.performance.now());
        }
        handleScroll();
      });

  const handleScroll = () => {
    const { innerHeight } = window;
    const { scrollHeight, scrollTop } = document.documentElement;
    const isAtBottom = scrollHeight - innerHeight <= scrollTop + 5;

    setIsCmpVisible(isCmpBoxVisible());
    setIsVisible(!isAtBottom);
  };

  useEffect(() => {
    fetchServerTime();

    window.addEventListener("scroll", handleScroll);

    clearTimer();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    clearTimer();
  }, [currentTime]);

  const updateTimer = () => {
    setIsCmpVisible(isCmpBoxVisible());

    const now = window.performance.now();
    if (!isOvertime && now - timeOffset > 3000) {
      fetchServerTime();
    }

    const total =
      endDate - (currentTime + window.performance.now() - timeOffset);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.ceil((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    const days = Math.floor(total / 1000 / 60 / 60 / 24);

    setIsOvertime(total < 0);

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const isCmpBoxVisible = () => {
    let cmpBox = document.querySelector("#cmpwrapper");
    if (!cmpBox) return false;
    let shadowRoot = cmpBox.shadowRoot;
    if (!shadowRoot) return false;
    let box = shadowRoot.querySelector("#cmpbox") as any;
    if (!box) return false;
    return box.style.display != "none";
  };

  const startTimer = () => {
    let { total, hours, days, minutes, seconds } = updateTimer();
    if (total >= 0) {
      setTimeString(
        `Angebot endet in  ${formatPeriod(days, "Tage")} ${formatPeriod(
          hours,
          "Stunde"
        )} ${formatPeriod(minutes, "Minute")}!`
      );
    }
  };

  const clearTimer = () => {
    startTimer();
    if (Ref.current) clearInterval(Ref.current);
    const interval = setInterval(() => {
      startTimer();
    }, 1000);
    Ref.current = interval as any;
  };

  let ctaButton;
  const { getLinkProps } = useLink();

  if (button) {
    const { link, internal, label, type, ...rest } = button.fields;

    const { href, linkTarget } = getLinkProps(link.fields);
    ctaButton = (
      <Button
        id={internal}
        variant="contained"
        color={"primary"}
        href={href}
        target={linkTarget}
        className={"teaser-buy-button"}
        {...rest}
        sx={{
          backgroundColor: buttonColor,
          color: buttonTextColor,
          "&:hover": {
            backgroundColor: buttonColor,
            boxShadow:
              "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);",
          },
        }}
      >
        {button?.fields.label}
      </Button>
    );
  }

  const timerElement = (
    <Typography
      variant="h4"
      sx={{
        fontSize: {
          xs: "16px",
          md: "20px",
        },
        textAlign: "center",
        color: fontColor,
      }}
    >
      {timeString}
    </Typography>
  );
  const closeButton = (
    <Button
      onClick={() => setIsClosed(true)}
      sx={{
        position: "absolute",
        width: "48px!important",
        height: "48px!important",
        minWidth: "48px!important",
        margin: 0,
        backgroundColor,
        borderRadius: "24px",
        transform: "translate(0,-50%)",
        cursor: "pointer",
        right: { xs: "8px", md: "24px" },
        top: 0,
        "&:hover": {
          backgroundColor,
        },
      }}
    >
      <CloseIcon
        style={{
          color: fontColor,
        }}
      ></CloseIcon>
    </Button>
  );

  const textBox = (
    <Box
      style={{
        display: "flex",

        justifyContent: "center",
        alignItems: "center",

        boxSizing: "border-box",
      }}
      sx={{
        flexDirection: { xs: "column", md: "row" },
        paddingRight: { xs: 0, md: "1.8em!important" },
      }}
    >
      {image && (
        <Box
          component={"img"}
          sx={{
            width: { xs: "38px", md: "58px" },
            height: { xs: "38px", md: "58px" },
            margin: "1em",
          }}
          alt={image?.fields.description}
          src={withHttps(image?.fields.file.url as any)}
        ></Box>
      )}
      <Box
        style={{
          display: "flex",

          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
          paddingRight: "1.8em!important",
          boxSizing: "border-box",
          marginLeft: "1em",
          marginRight: "1em",
        }}
        sx={{
          alignItems: {
            xs: "center",
            md: "flex-start",
          },
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: fontColor,
            textAlign: { xs: "center", md: "left" },
            fontSize: { xs: "28px", md: "38px" },
          }}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            sx={{
              color: fontColor,
              mt: theme.spacing(1),
              maxWidth: "100%",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );

  return (
    <ContentSectionThemeContext.Provider value={themeContextProvider}>
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          bottom: 0,
          left: 0,
          padding: { xs: theme.spacing(2), md: theme.spacing(4) },
          pt: { xs: theme.spacing(1), md: theme.spacing(4) },
          pb: { xs: theme.spacing(3), md: theme.spacing(4) },
          boxSizing: "border-box",

          zIndex: 99,
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: { xs: "column", md: "row" },

          backgroundColor,

          transform:
            (typeof window == "undefined" || window.performance.now() > 5000) &&
            !isClosed &&
            !isCmpVisible &&
            !isOvertime &&
            isVisible
              ? "none"
              : "translateY(calc(100% + 24px))",
          transition: "transform 1s ease-out",
          boxShadow: "0px 2px 4px 1px black",
        }}
      >
        {textBox}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {isSmallScreen && (
            <Box sx={{ mt: theme.spacing(3), mb: theme.spacing(3) }}>
              {button && ctaButton}
            </Box>
          )}
          {timerElement}
          {!isSmallScreen && (
            <Box sx={{ my: theme.spacing(2) }}>{button && ctaButton}</Box>
          )}
        </Box>
        {closeButton}
      </Box>
    </ContentSectionThemeContext.Provider>
  );
};

export default CountdownBar;

function formatPeriod(amount: number, label: string): string {
  return amount > 0 ? `${amount} ${label}${amount > 1 ? "n" : ""}` : "";
}
